/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.btn-orange-moon {
  background: #fc4a1a;  
  background: -webkit-linear-gradient(to right, #f7b733, #fc4a1a);  
  background: linear-gradient(to right, #f7b733, #fc4a1a); 
  color: #fff;
  border: 3px solid #eee;
  /* width: 300px; */
}




